import { observer } from 'mobx-react-lite';

import Link from 'next/link';
import { useRouter } from 'next/router';
// import { clientNamespaces, loadTranslations } from 'ni18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeyPress } from 'react-use';

import { LogoSVG, OutlineButton } from '@10x/foundation/src/components';
import { HamburgerBtn } from './HamburgerBtn';
import { NavItem } from './NavItem';
import { NavbarMobileMenu } from './NavbarMobileMenu';

import { classNames, isBrowser } from '@foundationPathAlias/utilities';
import { paths } from '@mainApp/src/config';
import { IOC_TOKENS, useMultipleInjection } from '@mainApp/src/ioc';
import { InteractiveContentTypesEnum } from '@mainApp/src/stores';

import dynamic from 'next/dynamic';

const NavbarActionsNoSSR = dynamic(
  () => import('./NavbarActions').then((module) => module.NavbarActions),
  { ssr: false }
);

const navigation = [
  { label: 'exploreCommunities', href: paths.explore },
  {
    label: 'support.label',
    href: paths.support,
  },
  { label: 'pro', href: paths.pro },
  { label: 'pricing.label', href: paths.pricing },
];

export function _Navbar({ cn }: { cn?: string }) {
  /**
   * https://github.com/i18next/react-i18next
   * 4. Declaring namespace dependencies
   */
  const { t } = useTranslation(['common', 'home']);
  const { locale, events } = useRouter();
  const [isEscPressed] = useKeyPress('Escape');
  const router = useRouter();
  const {
    authStore,
    systemStore,
    createCommunityStepperStore,
    interactiveStore,
    dimensionsStore,
  } = useMultipleInjection([
    IOC_TOKENS.systemStore,
    IOC_TOKENS.authStore,
    IOC_TOKENS.createCommunityStepperStore,
    IOC_TOKENS.interactiveStore,
    IOC_TOKENS.dimensionsStore,
  ]);
  const { isMobileNavOpened, setMobileNavOpen } = systemStore;
  const isCommunityCreated = createCommunityStepperStore.isCommunityCreated;
  const createdCommunitySlug = createCommunityStepperStore.createdCommunitySlug;

  const [outlineBtnText, setOutlineBtnText] = useState('');

  const openMobileNav = () => {
    setMobileNavOpen(true);
  };
  const hideMobileNav = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    // subscribe to next/router event
    events.on('routeChangeStart', hideMobileNav);
    return () => {
      // unsubscribe to event on unmount to prevent memory leak
      events.off('routeChangeStart', hideMobileNav);
    };
  }, [events]);

  if (isEscPressed && isMobileNavOpened) {
    hideMobileNav();
  }

  useEffect(() => {
    if (!isBrowser) return;

    const content = authStore.logged ? t('navbar.open10X') : t('navbar.signUp');
    setOutlineBtnText(content);
  }, [isBrowser]);

  return (
    <header
      className={classNames(
        'bg-background-primary dark:bg-background-primary-dark',
        cn
      )}
    >
      <div className="px-[24px] md:px-[48px]">
        <nav
          className="relative z-9  my-[24px] grid grid-cols-[auto_2fr_auto] grid-rows-[50px] 
        "
          aria-label="Navigation"
        >
          <div className="flex items-center">
            <Link href="/" className="hover-el">
              <>
                <span className="sr-only">{t('exploreCommunities')}</span>
                <LogoSVG />
              </>
            </Link>
          </div>

          <div
            className="md:space-between hidden flex-row items-center justify-between
          pl-[40px] md:flex
        "
          >
            <div className="flex flex-row items-center space-x-[40px]">
              {navigation.map((link) => (
                <NavItem key={link.label} link={link} locale={locale} />
              ))}
            </div>

            <div className="flex flex-row items-center space-x-[40px] md:ml-10">
              <NavbarActionsNoSSR />
            </div>
          </div>

          <div className="md:col-span-0 col-span-2 flex items-center justify-end">
            {!isMobileNavOpened && (
              <OutlineButton
                aria-label="Sign up or Open 10X"
                cn="md:hidden w-auto h-[38px] mr-[24px] rounded-[24px] items-center justify-center flex themed-text text-sm14SB"
                onClick={() => {
                  if (authStore.logged) {
                    // when saved model exists, should just redirect
                    if (isCommunityCreated) {
                      router.push(`${paths.home}/${createdCommunitySlug}`);
                    } else {
                      if (
                        dimensionsStore.isMobile &&
                        systemStore.isMobileNavOpened
                      ) {
                        systemStore.setMobileNavOpen(false);
                      }
                      createCommunityStepperStore.setModal(true);
                    }
                  } else {
                    authStore.setLoginMode(false);
                    interactiveStore.setActiveContentType(
                      InteractiveContentTypesEnum.AUTH,
                      true
                    );
                  }
                }}
              >
                {outlineBtnText}
              </OutlineButton>
            )}
            <HamburgerBtn
              showMobileNav={isMobileNavOpened}
              onShow={openMobileNav}
              onHide={hideMobileNav}
              className="md:hidden"
            />
          </div>
        </nav>
      </div>

      <NavbarMobileMenu
        completed={createCommunityStepperStore.completed}
        isLogged={authStore.logged}
        show={isMobileNavOpened}
        authStore={authStore}
        interactiveStore={interactiveStore}
        dimensionsStore={dimensionsStore}
        systemStore={systemStore}
        createCommunityStepperStore={createCommunityStepperStore}
      >
        {navigation.map((link) => (
          <NavItem key={link.label} link={link} locale={locale} />
        ))}
      </NavbarMobileMenu>
    </header>
  );
}

export const Navbar = observer(_Navbar);
